import * as React from 'react';
import styled from 'styled-components';
import Cta from '../Cta';
import Search from '../Search';
import { Description, SectionHeading } from '../Typography';

const Container = styled.div``;

export default function NotFound() {
	return (
		<Container>
			<SectionHeading>
				The requested resource could not be found!
			</SectionHeading>
			<Description className="centered has-gutter">
				Please see if the following resources can help you.
			</Description>
			<Search searchScope="site" />
			<Cta.Group>
				<Cta to="/account/">Customer Login</Cta>
				<Cta to="/pricing/">Get Started</Cta>
				<Cta to="/docs/">Documentation</Cta>
				<Cta to="/faqs/">FAQs</Cta>
				<Cta to="/licensing/">Licensing</Cta>
			</Cta.Group>
		</Container>
	);
}
