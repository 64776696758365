import React from 'react';
import styled from 'styled-components';
import { docArticleContainerCss, gv, ns } from '../../utils';
import Cta from '../Cta';

const Divider = styled.hr`
	height: 2px;
	border-radius: 2px;
	border: 0 none;
	width: 40%;
	max-width: 150px;
	background-color: ${gv('borderColorLight')};
	margin: ${props => ns(props.theme.gutter * 2)} auto;
`;

const Title = styled.p`
	margin: 0 0 0.75em 0;
	padding: 0;
	font-size: ${props => ns(props.theme.fz.large5)};
	font-weight: bold;
	font-family: ${props => props.theme.fontFamilyHeading};
	text-align: center;
`;

const Description = styled.p`
	margin: 0 auto 1em auto;
	padding: 0;
	font-size: ${props => ns(props.theme.fz.large1)};
	font-weight: normal;
	max-width: 500px;
	line-height: 1.2;
	font-family: ${props => props.theme.fontFamilyHeading};
	text-align: center;
`;

const Container = styled.aside`
	${docArticleContainerCss};
	padding-top: 0;
	max-width: 100%;

	&.docRoot,
	&.regular {
		max-width: unset;
		padding: 0;
		margin: ${props => ns(props.theme.gutter * 3)} 0;
		${Divider} {
			margin: ${props => ns(props.theme.gutter * 3)} 0;
			width: 100%;
			max-width: unset;
		}
	}
	&.regular {
		${Divider} {
			display: none;
		}
	}
`;

export type NewSupportProps = {
	type?: 'docRoot' | 'singleDoc' | 'regular';
};
export default function NewSupport({ type = 'singleDoc' }: NewSupportProps) {
	return (
		<Container className={type}>
			<Divider />
			<Title>Still no luck? We can help!</Title>
			<Description>
				Submit a new support request from the link below and we will get back to
				you as soon as we can.
			</Description>
			<Cta.Group
				size="regular"
				align="center"
				hasGutterHorizontal
				hasGutterVertical
			>
				<Cta size="regular" to="/contact/" type="primary">
					Submit New Ticket
				</Cta>
			</Cta.Group>
		</Container>
	);
}
