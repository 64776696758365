import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import NotFound from '../NotFound';
import {
	Body,
	TocContainer,
	AvailabilityTagContainer,
	DocArticle,
	DocTitle,
} from './styled';
import Toc from './Toc';
import AvailableInTags from '../AvailableInTags';
import YoutubeEmbed from '../YoutubeEmbed';
import NextPrevLink from '../NextPrevLink';
import NewSupport from '../NewSupport';
import DocsSidebar from '../DocsSidebar';
import { Constrain } from '../Containers';
import MDXProvider from '../Typography/MDXProvider';

export default function Doc(props: {
	data: GatsbyTypes.SingleDocQuery;
	docType: string;
	slug: string;
}) {
	const {
		data: { mdx },
		docType,
		slug,
	} = props;
	if (!mdx) {
		return <NotFound />;
	}
	return (
		<Constrain>
			<DocsSidebar.Grid>
				<DocsSidebar />
				<DocsSidebar.Content>
					<DocTitle>{mdx.frontmatter?.title}</DocTitle>
					<AvailabilityTagContainer>
						<AvailableInTags neededPlan={mdx.frontmatter?.neededPlan} />
					</AvailabilityTagContainer>
					{mdx.frontmatter?.youtubeVideoId ? (
						<YoutubeEmbed videoId={mdx.frontmatter.youtubeVideoId} />
					) : null}
					<Body>
						<TocContainer>
							<Toc items={(mdx.tableOfContents as any)?.items ?? undefined} />
						</TocContainer>
						<DocArticle>
							<MDXProvider>
								<MDXRenderer>{mdx.body}</MDXRenderer>
							</MDXProvider>
							<NextPrevLink docType={docType} slug={slug} />
							<NewSupport />
						</DocArticle>
					</Body>
				</DocsSidebar.Content>
			</DocsSidebar.Grid>
		</Constrain>
	);
}
