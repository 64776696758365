import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DocTypeNav from './DocTypeNav';
import { docTypes } from '../../data/docTypes';
import { useDocData } from '../DocDataProvider';
import {
	buttonResetCss,
	gv,
	getMultipleTransitionsWithWillChange,
	ns,
	bp,
} from '../../utils';
import Search from '../Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';

export const SidebarContainer = styled.nav`
	flex: 0 0 280px;
	position: fixed;
	z-index: 9999;
	height: calc(100vh - ${props => ns(props.theme.controlHeightBase * 1.5)});
	padding: ${props => ns(props.theme.gutter * 0.75)};
	padding-bottom: 100px;
	overflow-y: auto;
	top: ${props => ns(props.theme.controlHeightBase * 1.5)};
	background-color: ${gv('appBackgroundColor')};
	box-shadow: ${props => props.theme.boxShadowRight};
	border-right: 4px solid ${gv('borderColorLight')};
	left: -286px;
	width: 290px;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(['left'], props.theme.transition)};
	&.sidebar--visible {
		left: 0px;
		opacity: 1;
	}

	@media ${props => bp(props, 'desktop')} {
		position: sticky;
		left: initial;
		width: 300px;
		flex: 0 0 300px;
		opacity: 1;
		height: initial;
		box-shadow: none;
		align-self: flex-start;
		padding: ${props => ns(props.theme.gutter)};
		padding-top: ${props => ns(props.theme.gutter * 1.5)};
		padding-bottom: 250px;
		border-right: 0 none;
	}
`;

export const ToggleButton = styled.button`
	${buttonResetCss};

	@media ${props => bp(props, 'desktop')} {
		display: none;
	}

	position: fixed;
	z-index: 10000;
	bottom: ${props => ns(props.theme.gutter * 6)};
	left: 4px;
	height: ${props => ns(props.theme.controlHeightBase * 1.25)};
	width: ${props => ns(props.theme.controlHeightBase * 1)};
	box-shadow: ${props => props.theme.boxShadowRight};
	background-color: ${gv('borderColorLight')};
	color: ${gv('textColor')};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(['left'], props.theme.transition)};

	&.is-active {
		left: 289px;
	}
`;

export const DocSidebarMainGrid = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;
	min-height: 100vh;
`;
export const DocSidebarContent = styled.div`
	flex: 1 0 calc(100% - 300px);
	width: calc(100% - 300px);
	max-width: 100%;
	padding: ${props => ns(props.theme.gutter)};
	@media ${props => bp(props, 'tablet')} {
		padding-left: ${props => ns(props.theme.gutter * 2)};
	}
`;

const SearchContainer = styled.div`
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
`;

export default function DocsSidebar() {
	// sidebar visibility
	const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
	const data = useDocData()!;
	const sidebarRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handler = (event: MouseEvent) => {
				if (
					(event.target as HTMLElement).isSameNode(sidebarRef.current) ||
					sidebarRef.current?.contains(event.target as HTMLElement)
				) {
					return;
				}
				setSidebarVisible(false);
			};
			window.document.addEventListener('click', handler);
			return () => {
				window.document.removeEventListener('click', handler);
			};
		}
		return () => {};
	}, [setSidebarVisible]);

	return (
		<>
			<SidebarContainer
				className={`sidebar sidebar--${sidebarVisible ? 'visible' : 'hidden'}`}
				ref={sidebarRef}
			>
				<SearchContainer>
					<Search
						uiType="hotkey"
						searchScope="docs"
						label="Search documentation…"
					/>
				</SearchContainer>
				<DocTypeNav
					key="video-tutorials"
					queryData={{ edges: [] }}
					label="Video Tutorials"
					docType="video-tutorials"
				/>
				{docTypes.map(docType => {
					const queryData = data[docType.accessor];
					return (
						<DocTypeNav
							key={docType.accessor}
							queryData={queryData}
							label={docType.label}
							docType={docType.docType}
						/>
					);
				})}
			</SidebarContainer>
			<ToggleButton
				aria-label="Toggle documentation sidebar visibility"
				className={`hamburger ${sidebarVisible ? 'is-active' : 'is-inactive'}`}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setSidebarVisible(v => {
						const newVisibility = !v;
						if (newVisibility) {
							sidebarRef.current?.focus();
						}
						return newVisibility;
					});
				}}
			>
				<FontAwesomeIcon icon={sidebarVisible ? faArrowLeft : faArrowRight} />
			</ToggleButton>
		</>
	);
}
DocsSidebar.Grid = DocSidebarMainGrid;
DocsSidebar.Content = DocSidebarContent;
