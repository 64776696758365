import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { useDocData } from '../DocDataProvider';
import {
	docArticleContainerCss,
	gv,
	getMultipleTransitionsWithWillChange,
	getNodePathForDocPage,
	ns,
} from '../../utils';
import { docTypes } from '../../data/docTypes';

const Container = styled.nav`
	${docArticleContainerCss};
	padding-left: 0;
	padding-right: 0;
	display: grid;
	grid-gap: ${props => ns(props.theme.gutter * 2)};
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;
const AnchorSubtitle = styled.span`
	display: block;
	text-transform: uppercase;
	font-weight: bold;
	color: ${gv('borderColorBase')};
	font-size: ${props => ns(props.theme.fz.small1)};
	margin: 0 0 0.5em 0;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['color'],
			props.theme.transitionControl
		)};
`;
const AnchorTitle = styled.span`
	color: ${gv('textColor')};
	font-size: ${props => ns(props.theme.fz.base)};
	line-height: 1.2;
`;
const AnchorIcon = styled.span`
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['color'],
			props.theme.transitionControl
		)};
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: ${props => ns(props.theme.fz.large3)};
	left: ${props => ns(props.theme.gutter)};
	color: ${gv('borderColorBase')};
`;
const Anchor = styled(Link)`
	background-color: ${gv('backgroundShade')};
	padding: ${props => ns(props.theme.gutter)};
	box-shadow: 2px 2px 0 0 ${gv('controlShadowGhost')};
	border: 1px solid ${gv('borderColorSplit')};
	color: ${gv('textColor')};
	text-decoration: none;
	font-family: ${props => props.theme.fontFamilyHeading};
	position: relative;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['border-color', 'color', 'box-shadow'],
			props.theme.transitionControl
		)};
	&:hover,
	&:focus {
		color: ${gv('textColor')};
		border-color: ${gv('primaryLightColor')};
		box-shadow: 4px 4px 0 0 ${gv('controlShadowGhost')};
		${AnchorTitle} {
			color: ${gv('textColor')};
		}
		${AnchorSubtitle},
		${AnchorIcon} {
			color: ${gv('primaryLightColor')};
		}
	}
	&.previous {
		text-align: right;
		padding-left: 100px;
	}
	&.next {
		padding-right: 100px;
		${AnchorIcon} {
			left: auto;
			right: ${props => ns(props.theme.gutter)};
		}
	}
`;

export type NextPrevLinkProps = {
	slug: string;
	docType: string;
};
export default function NextPrevLink(props: NextPrevLinkProps) {
	const docData = useDocData();
	const { slug, docType } = props;
	let prevLink: React.ReactNode = <div></div>;
	let nextLink: React.ReactNode = <div></div>;
	const docAccessor = docTypes.find(dt => dt.docType === docType);
	const allDocData = docAccessor ? docData?.[docAccessor.accessor] : null;
	if (allDocData) {
		const currentIndex = allDocData.edges.findIndex(
			({ node }) => node.fields?.slug === slug
		);
		const currentDocTypeIndex = docTypes.findIndex(
			dt => dt.docType === docType
		);
		const prevDocTypeIndex = currentDocTypeIndex - 1;
		const nextDocTypeIndex = currentDocTypeIndex + 1;
		if (currentIndex !== -1 && currentDocTypeIndex !== -1) {
			const prevIndex = currentIndex - 1;
			const nextIndex = currentIndex + 1;
			if (prevIndex > -1 && allDocData.edges[prevIndex]) {
				prevLink = (
					<Anchor
						to={getNodePathForDocPage(allDocData.edges[prevIndex].node)}
						className="previous"
					>
						<AnchorIcon>
							<FontAwesomeIcon icon={faArrowLeft} />
						</AnchorIcon>
						<AnchorSubtitle>Previous</AnchorSubtitle>
						<AnchorTitle>
							{allDocData.edges[prevIndex].node.frontmatter?.menuName}
						</AnchorTitle>
					</Anchor>
				);
			} else if (currentDocTypeIndex > -1 && docTypes[prevDocTypeIndex]) {
				prevLink = (
					<Anchor
						to={`/docs/${docTypes[prevDocTypeIndex].docType}/`}
						className="previous"
					>
						<AnchorIcon>
							<FontAwesomeIcon icon={faArrowLeft} />
						</AnchorIcon>
						<AnchorSubtitle>Previous</AnchorSubtitle>
						<AnchorTitle>{docTypes[prevDocTypeIndex].label}</AnchorTitle>
					</Anchor>
				);
			}
			if (allDocData.edges[nextIndex]) {
				nextLink = (
					<Anchor
						to={getNodePathForDocPage(allDocData.edges[nextIndex].node)}
						className="next"
					>
						<AnchorIcon>
							<FontAwesomeIcon icon={faArrowRight} />
						</AnchorIcon>
						<AnchorSubtitle>Next</AnchorSubtitle>
						<AnchorTitle>
							{allDocData.edges[nextIndex].node.frontmatter?.menuName}
						</AnchorTitle>
					</Anchor>
				);
			} else if (docTypes[nextDocTypeIndex]) {
				nextLink = (
					<Anchor
						to={`/docs/${docTypes[nextDocTypeIndex].docType}/`}
						className="next"
					>
						<AnchorIcon>
							<FontAwesomeIcon icon={faArrowRight} />
						</AnchorIcon>
						<AnchorSubtitle>Next</AnchorSubtitle>
						<AnchorTitle>{docTypes[nextDocTypeIndex].label}</AnchorTitle>
					</Anchor>
				);
			}
		}
	}

	return (
		<Container>
			{prevLink}
			{nextLink}
		</Container>
	);
}
