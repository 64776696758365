import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {
	gv,
	getMultipleTransitionsWithWillChange,
	getNodePathForDocPage,
	ns,
	buttonResetCss,
} from '../../utils';
import { Badge } from '../Typography';

const NavLink = styled(Link)`
	color: ${gv('textColorSecondary')};
	// prettier-ignore
	padding: ${props => ns(props.theme.gutter / 2)};
	padding-left: ${props => ns(props.theme.gutter)};
	display: block;
	background-color: ${gv('appBackgroundColor')};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.small1)};
	transition: ${props =>
		getMultipleTransitionsWithWillChange(
			['background-color', 'color'],
			props.theme.transitionControl
		)};
	&:hover {
		text-decoration: underline;
		color: ${gv('textColor')};
	}
`;
const Heading = styled.h4`
	margin: 0;
	padding: 0;
	padding: ${props => ns(props.theme.gutter * 0.75)} 0;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	${NavLink} {
		font-size: ${props => ns(props.theme.fz.small1)};
		font-weight: bold;
		color: ${gv('textColorSecondary')};
		margin: 0;
		padding: 0;
		&.is-active {
			color: ${gv('headingColor')};
			text-decoration: underline;
		}
	}
`;
const HeadingLabel = styled.span``;
const HeadingIcon = styled.button`
	${buttonResetCss};
	margin: 0 ${props => ns(props.theme.gutter * 0.75)} 0
		${props => ns(props.theme.gutter - 6)};
	color: ${gv('textColorSecondary')};
	&.is-active {
		color: ${gv('textColor')};
	}
	width: 1em;
`;
const List = styled.ul`
	list-style: none;
	margin: 0 0 0 ${props => ns(props.theme.gutter)};
	border-left: 1px solid ${gv('borderColorBase')};
	padding: 0;
`;
const Item = styled.li`
	margin: 0;
	padding: 0;
	${NavLink} {
		font-size: ${props => ns(props.theme.fz.small1)};
		font-weight: normal;
		&.is-active {
			font-weight: bold;
		}
	}
`;
const Container = styled.div`
	a {
		text-decoration: none;
	}
	&.is-active {
		${Item} ${NavLink} {
			color: ${gv('headingColor')};
			&.is-active {
				color: ${gv('textColor')};
			}
		}
	}
`;

export type DocTypeNavProps = {
	queryData: GatsbyTypes.GroupedDocsQuery['administrations'];
	docType: string;
	label: string;
	isDocTypeGeneral?: boolean;
};
export default function DocTypeNav({
	queryData,
	docType,
	label,
	isDocTypeGeneral = false,
}: DocTypeNavProps) {
	const location = useLocation();
	const isHeadingActive = location.pathname.includes(
		isDocTypeGeneral ? docType : `/${docType}/`
	);
	const [headingOpen, setHeadingOpen] = useState<boolean>(isHeadingActive);

	return (
		<Container className={classNames({ 'is-active': isHeadingActive })}>
			<Heading>
				<HeadingIcon
					onClick={e => {
						e.preventDefault();
						setHeadingOpen(o => !o);
					}}
					className={classNames({ 'is-active': headingOpen })}
				>
					<FontAwesomeIcon
						icon={headingOpen ? faChevronDown : faChevronRight}
					/>
				</HeadingIcon>
				<NavLink
					className={classNames({ 'is-active': isHeadingActive })}
					to={isDocTypeGeneral ? docType : `/docs/${docType}/`}
				>
					<HeadingLabel>{label}</HeadingLabel>
				</NavLink>
			</Heading>
			{headingOpen ? (
				<List>
					{queryData.edges.map(({ node }) => {
						const url = getNodePathForDocPage(node);
						return (
							<Item key={node.fields?.slug}>
								<NavLink
									to={url}
									className={classNames({
										'is-active': url === location.pathname,
									})}
								>
									{node.frontmatter?.menuName ?? node.fields?.slug}
									<Badge>{node.timeToRead}m</Badge>
								</NavLink>
							</Item>
						);
					})}
				</List>
			) : null}
		</Container>
	);
}
