import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { bp, gridContainerCss, gv, ns } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

const Tag = styled.span`
	margin: ${props => ns(props.theme.gutter * 0.25)};
	height: ${props => ns(props.theme.controlHeightBase * 0.6)};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 ${props => ns(props.theme.gutter / 2)};
	font-family: ${props => props.theme.fontFamilyHeading};
	font-weight: bold;
	font-size: ${props => ns(props.theme.fz.small2)};
	line-height: 1;
	background-color: ${gv('secondaryAccentColor')};
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	border: 1px solid ${gv('secondaryAccentColor')};
	color: ${gv('primaryBgText')};
	text-transform: uppercase;

	.svg-inline--fa {
		margin-right: 4px;
	}

	&.disabled {
		border-color: ${gv('disabledColor')};
		background-color: ${gv('disabledBackgroundColor')};
		color: ${gv('textColorSecondary')};
	}
`;

const Container = styled.aside`
	${gridContainerCss};
	margin-left: ${props => ns(props.theme.gutter * -0.25)};
	margin-right: ${props => ns(props.theme.gutter * -0.25)};
	&.has-top-gutter {
		margin-top: ${props => ns(props.theme.gutter)};
	}
	justify-content: flex-start;
	&.centered {
		justify-content: center;
	}
	&.center-desktop-left {
		justify-content: center;
		@media ${props => bp(props, 'desktop')} {
			justify-content: flex-start;
		}
	}

	&.size-small {
		${Tag} {
			margin: ${props => ns(props.theme.gutter * 0.1)};
			border-radius: ${props => ns(props.theme.borderRadiusBase / 2)};
			font-size: ${props => ns(props.theme.fz.small2 - 2)};
			height: ${props => ns(props.theme.controlHeightBase * 0.5)};
			padding: 0 ${props => ns(props.theme.gutter / 4)};
		}
	}
`;

export type PlanType = 'free' | 'starter' | 'professional' | 'business';
export type AvailableInTagsProps = {
	neededPlan?: PlanType | string;
	layout?: 'centered' | 'left' | 'center-desktop-left';
	hasTopGutter?: boolean;
	size?: 'small' | 'regular';
};
const validTags: PlanType[] = ['free', 'starter', 'professional', 'business'];
export default function AvailableInTags(props: AvailableInTagsProps) {
	const {
		neededPlan,
		layout = 'left',
		hasTopGutter = false,
		size = 'regular',
	} = props;

	let parsedPlan: PlanType;
	if (neededPlan && validTags.includes(neededPlan as any)) {
		parsedPlan = neededPlan as PlanType;
	} else {
		parsedPlan = 'free';
	}
	const planIndex = validTags.findIndex(t => t === parsedPlan);
	return (
		<Container
			className={classNames('availability', layout, {
				'has-top-gutter': hasTopGutter,
				[`size-${size}`]: true,
			})}
		>
			{validTags.slice(0, planIndex).map(t => (
				<Tag key={t} className={classNames('disabled', t)}>
					<FontAwesomeIcon aria-label="Not available in" icon={faTimes} /> {t}
				</Tag>
			))}
			{validTags.slice(planIndex).map(t => (
				<Tag key={t} className={classNames('enabled', t)}>
					<FontAwesomeIcon aria-label="Available in" icon={faCheck} /> {t}
				</Tag>
			))}
		</Container>
	);
}
