import styled from 'styled-components';
import { ns } from '../../utils';
import { PageTitle } from '../Typography';

export const TocContainer = styled.aside``;

export const DocArticle = styled.div``;

export const DocTitle = styled(PageTitle)`
	max-width: 600px;
`;

export const Body = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-between;
	${DocArticle} {
		flex: 0 0 100%;
		width: 100%;
		max-width: 100%;
	}
	${TocContainer} {
		flex: 0 0 400px;
		width: 400px;
		max-width: 100%;
	}

	@media screen and (min-width: 1200px) {
		${DocArticle} {
			flex: 0 0 600px;
			width: 600px;
			max-width: 100%;
		}
		${TocContainer} {
			flex-basis: 210px;
			width: 210px;
			order: 1;
			position: sticky;
			top: ${props =>
				ns(props.theme.controlHeightBase * 1.5 + props.theme.gutter)};
		}
	}
`;

export const AvailabilityTagContainer = styled.div`
	margin: ${props => ns(props.theme.gutter)} 0;
`;
