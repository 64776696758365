import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Doc from '../components/Doc';
import Seo from '../components/Seo';
import DocDataProvider from '../components/DocDataProvider';

type PageContextType = {
	slug: string;
	docType: string;
};

export default function DocTemplate(
	props: PageProps<GatsbyTypes.SingleDocQuery, PageContextType>
) {
	const {
		data: { mdx },
		pageContext: { docType, slug },
	} = props;

	return (
		<DocDataProvider>
			<Seo
				description={mdx?.frontmatter?.excerpt}
				title={mdx?.frontmatter?.title}
				keywords={mdx?.frontmatter?.keywords}
				featuredImage={
					mdx?.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData
				}
			/>
			<Doc data={props.data} docType={docType} slug={slug} />
		</DocDataProvider>
	);
}

export const query = graphql`
	query SingleDoc($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				type
				neededPlan
				excerpt
				featuredImage {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, width: 1200)
					}
				}
				keywords
				youtubeVideoId
			}
			body
			excerpt(pruneLength: 200, truncate: true)
			tableOfContents
			timeToRead
			fields {
				docType
			}
		}
	}
`;
